<template>
  <div class="card" v-loading.fullscreen.lock="loading">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="productImportConstants.SEARCHPRODUCT"
          />
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
            <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
            <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="false"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-sizes="{ row: data }">
          <span v-for="(size, index) in data.sizes" :key="index">
            <span class="badge badge-light-primary"> {{ size }} </span>
          </span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.EDIT" placement="top">
            <el-button type="text" size="small" @click.prevent="$router.push({ name: 'edit-imported-product', params: { productexcelid: $route.params.id,id: data.product_id } })" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-pencil-square"></i></el-button>
          </el-tooltip>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRouter,useRoute } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "product-listing",
  components: {
    Datatable,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus();
    const router = useRouter();
    const route = useRoute();
    const total = ref(0);
    const tableData = ref([]);
    const initProductHistoryData = ref([]);
    const loading = ref(false);
    const search = ref("");
    const pageSize = ref(10);
    const currentpage = ref(1);
    const noDataFound = ref("No Data Found!");
    const updateProps = ref(null);
    const generalConstants = globalConstant.general;
    const productImportConstants = globalConstant.productImport;
    const searchParams = reactive({
      search: "",
      recordsPerPage: 10,
      pageNub: 1,
      sortby: 'latest',
      product_import_history_id: route.params.id
    });

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push({ name: 'import-products-history'});
      }
    })

    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: true,
        width: "w-20px",
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
        width: "w-150px",
      },
      {
        name: "Size",
        key: "sizes",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);
  
    //Pagination size change event
    const handleSizeChange = (number) => {
      pageSize.value = number;
      getProducts();
    };

    //Pagination change event
    const handleCurrentChange = (number) => {
      currentpage.value = number;
      getProducts();
    };

    //Get all products
    const getProducts = async () => {
      loading.value = true;
      await ApiService.query("product-import-history", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data) {
            tableData.value = [];
            data.data.data.forEach((obj) => {
              let size = [];
              obj.sizes.forEach((sizeObj) => {
                size.push(sizeObj.size);
              });
              tableData.value.push({
                product_id: obj.product_id,
                name: obj.name,
                description: obj.description,
                sizes: size,
                product_sizes: obj.sizes,
                sku_code: obj.sku_code,
                status: obj.status == 1 ? true : false,
              });
              initProductHistoryData.value.push({
                product_id: obj.product_id,
                name: obj.name,
                description: obj.description,
                sizes: size,
                product_sizes: obj.sizes,
                sku_code: obj.sku_code,
                status: obj.status == 1 ? true : false,
              });
            });
            total.value = data.data.total;
            setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{"name": formatText('products'), "path": "products"}, {"name": formatText('Import Products History'), "path": "import-products-history"}, { name: formatText(route.name), active: true }]);
            loading.value = false;
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
          tableData.value = [];
          total.value =  0;
        });
    };

    //Handle search event
    const handleSearch = () => {
      tableData.value.splice(0, tableData.value.length, ...initProductHistoryData.value);
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    }

    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (obj[key] &&  obj[key].length > 0 &&!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = '';
        handleSearch();
      }
    }
    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':'bi bi-arrow-left fs-4' }]);
    }
    //Set breadcrumbs and get the products
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('products'), "path": "products"}, {"name": formatText('Import Products History'), "path": "import-products-history"}, { name: formatText(route.name), active: true }]);
      await getProducts();
      generateHeaderToolbarButtons();
    })

    return {
      search,
      tableData,
      getProducts,
      total,
      handleSizeChange,
      handleCurrentChange,
      loading,
      handleSearch,
      resetSearch,
      noDataFound,
      updateProps,
      generalConstants,
      productImportConstants,
      searchParams,
      tableHeader
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>